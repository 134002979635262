// extracted by mini-css-extract-plugin
export var cards = "index-module--cards--zq8Jl";
export var container = "index-module--container--uIcAg";
export var faqbg = "index-module--faqbg--H9axu";
export var hero = "index-module--hero--a7aEq";
export var integrated_bg = "index-module--integrated_bg--FekvT";
export var limitWidthOnLargeScreens = "index-module--limitWidthOnLargeScreens--BiBkk";
export var margins = "index-module--margins--1UPSk";
export var outerContainerClassName = "index-module--outerContainerClassName--Zc89M";
export var personalizedOversightContainer = "index-module--personalizedOversightContainer--US4f2";
export var roibg = "index-module--roibg--UT34b";
export var universalProtocolCards = "index-module--universalProtocolCards--oEliH";